<template>
    <div>
        <p style="text-align: right;">最后更新时间：{{ info.update_at }}</p>
        <a-collapse v-model="activeKey">
            <a-collapse-panel key="1" header="企业基本信息">
                <template>
                    <a-descriptions layout="vertical" bordered>
                        <a-descriptions-item label="成立日期">
                            {{ info.start_date ||'-' }}
                        </a-descriptions-item>
                        <a-descriptions-item label="企业性质">
                            {{ entType(info.ent_type) ||'-' }}
                        </a-descriptions-item>
                        <a-descriptions-item label="注册资本">
                            {{ info.regist_capi  ||'-'}}
                        </a-descriptions-item>
                        <a-descriptions-item label="法人代表">
                            {{ info.oper_name ||'-' }}
                        </a-descriptions-item>
                        <a-descriptions-item label="企业邮箱">
                            {{ info.email  ||'-'}}
                        </a-descriptions-item>
                        <a-descriptions-item label="企业联系电话">
                            {{ info.tel  ||'-'}}
                        </a-descriptions-item>
                        <a-descriptions-item label="注册地址">
                            {{ info.address ||'-' }}
                        </a-descriptions-item>
                    </a-descriptions>
                </template>
            </a-collapse-panel>
            <a-collapse-panel key="2" header="企业荣誉资质" :disabled="false">
                <a-table :rowKey="(record, index) => record.menuId" :columns="table.columns" :data-source="table.data"
                    tableLayout="fixed" :pagination="pagination" @change="pageChange">
                </a-table>
            </a-collapse-panel>
            <a-collapse-panel key="3">
                <template slot="header">企业人员</template>
                <div class="tabbox">
                    <div class="tabtitle" style="">
                        <div style="flex:1">参保人数</div>
                    </div>
                    <div class="tabtext" style="">
                        <div style="flex:1">{{ info.insured_count || '未知' }}</div>
                    </div>
                </div>
                <a-collapse >
                    <a-collapse-panel key="9">
                        <template slot="header">主要人员（工商登记） <a-tooltip placement="top">
                                <template slot="title">
                                    <span>仅展示前100条数据</span>
                                </template>
                                <a-icon type="info-circle" />
                            </a-tooltip></template>
                        <a-table :rowKey="(record, index) => record.menuId" :columns="table.columns2"
                            :data-source="table.data2"  :pagination="pagination2" @change="pageChange2" tableLayout="fixed">
                        </a-table>
                    </a-collapse-panel>
                </a-collapse>
            </a-collapse-panel>
            <a-collapse-panel key="4">
                <template slot="header">股东信息 <a-tooltip placement="top">
                        <template slot="title">
                            <span>仅展示前100条数据</span>
                        </template>
                        <a-icon type="info-circle" />
                    </a-tooltip></template>
                <a-table  :pagination="pagination3" @change="pageChange3" :rowKey="(record, index) => record.menuId" :columns="table.columns3" :data-source="table.data3"
                    tableLayout="fixed">
                </a-table>
            </a-collapse-panel>
            <a-collapse-panel key="5" header="融资信息" :disabled="false">
                <a-table :pagination="pagination4" @change="pageChange4" :rowKey="(record, index) => record.menuId" :columns="table.columns4" :data-source="table.data4"
                    tableLayout="fixed">
                </a-table>
            </a-collapse-panel>
            <a-collapse-panel key="6" header="知识产权信息">
                <div class="tabbox">
                    <div class="tabtitle" style="">
                        <div style="flex:1">
                            发明公布专利数量
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>仅展示状态为“有效的发明公布”专利数量</span>
                                </template>
                                <a-icon type="info-circle" style="color:  color: rgba(0, 0, 0, 0.85);" />
                            </a-tooltip>
                        </div>
                        <div style="flex:1">发明授权专利数量 <a-tooltip placement="top">
                                <template slot="title">
                                    <span>仅展示状态为“有效的发明授权”专利数量</span>
                                </template>
                                <a-icon type="info-circle" style="color:  color: rgba(0, 0, 0, 0.85);" />
                            </a-tooltip></div>
                        <div style="flex:1">实用新型专利数量 <a-tooltip placement="top">
                                <template slot="title">
                                    <span>仅展示状态为“有效的实用新型”专利数量</span>
                                </template>
                                <a-icon type="info-circle" style="color:  color: rgba(0, 0, 0, 0.85);" />
                            </a-tooltip></div>
                        <div style="flex:1">外观设计专利数量 <a-tooltip placement="top">
                                <template slot="title">
                                    <span>仅展示状态为“有效的外观设计”专利数量</span>
                                </template>
                                <a-icon type="info-circle" style="color:  color: rgba(0, 0, 0, 0.85);" />
                            </a-tooltip></div>
                    </div>
                    <div class="tabtext" style="">
                        <div style="flex:1">{{ info.invention_announcement_count ||'-' }}</div>
                        <div style="flex:1">{{ info.licensing_invention_count ||'-' }}</div>
                        <div style="flex:1">{{ info.utility_model_count ||'-' }}</div>
                        <div style="flex:1">{{ info.appearance_design_count ||'-' }}</div>
                    </div>
                    <div class="tabtitle" style="">
                        <div style="flex:1">国际专利数量 <a-tooltip placement="top">
                                <template slot="title">
                                    <span>国际专利数量可识别的最大值为5000，专利有效状态暂无法识别</span>
                                </template>
                                <a-icon type="info-circle" style="color:  color: rgba(0, 0, 0, 0.85);" />
                            </a-tooltip></div>
                        <div style="flex:1">软件著作权数量</div>
                        <div style="flex:1">商标数量 <a-tooltip placement="top">
                                <template slot="title">
                                    <span>仅展示“已注册”状态的商标数量</span>
                                </template>
                                <a-icon type="info-circle" />
                            </a-tooltip></div>
                    </div>
                    <div class="tabtext" style="">
                        <div style="flex:1">{{ info.international_patent_count  ||'-'}}</div>
                        <div style="flex:1">{{ info.copy_right_count ||'-' }}</div>
                        <div style="flex:1">{{ info.national_trademark_count ||'-' }}</div>
                    </div>
                </div>
            </a-collapse-panel>
        </a-collapse>
    </div>
</template>
<script>
import * as api from '@/api/enterprise/enterprise'

export default {
    data() {
        return {
            id: '',
            table: {
                columns: [
                    {
                        title: '资质名称',
                        dataIndex: 'name',
                        key: 'name'
                    },
                    {
                        title: '资质级别',
                        dataIndex: 'level',
                        key: 'level'
                    },
                    {
                        title: '资质开始日期',
                        dataIndex: 'beging_date',
                        key: 'beging_date'
                    },
                    {
                        title: '资质结束日期',
                        dataIndex: 'dead_line',
                        key: 'dead_line'
                    },
                ],
                columns2: [
                    {
                        title: '姓名',
                        dataIndex: 'name',
                        key: 'name'
                    },
                    {
                        title: '职务',
                        dataIndex: 'job',
                        key: 'job'
                    },
                ],
                columns3: [
                    {
                        title: '姓名',
                        dataIndex: 'stock_name',
                        key: 'stock_name'
                    },
                    {
                        title: '投资人类型',
                        dataIndex: 'stock_type',
                        key: 'stock_type'
                    },
                    {
                        title: '持股比例',
                        dataIndex: 'stock_percent',
                        key: 'stock_percent'
                    },
                    {
                        title: '持股日期',
                        dataIndex: 'stake_date',
                        key: 'stake_date'
                    },
                ],
                columns4: [
                    {
                        title: '日期',
                        dataIndex: 'date',
                        key: 'date'
                    },
                    {
                        title: '产品名称',
                        dataIndex: 'product_name',
                        key: 'product_name'
                    },
                    {
                        title: '融资轮次',
                        dataIndex: 'round',
                        key: 'round'
                    },
                    {
                        title: '金额',
                        dataIndex: 'amount',
                        key: 'amount'
                    },
                    {
                        title: '估值',
                        dataIndex: 'valuation',
                        key: 'valuation'
                    },
                    {
                        title: '投资方',
                        dataIndex: 'investment',
                        key: 'investment'
                    },
                ],
                data: [],
                data2: [],
                data4: [],
            },
            pagination: {
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total, range) => {
                    return `共${total}条`
                }
            },
            pagination2: {
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total, range) => {
                    return `共${total}条`
                }
            },
            pagination3: {
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total, range) => {
                    return `共${total}条`
                }
            },
            pagination4: {
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total, range) => {
                    return `共${total}条`
                }
            },
            activeKey: [1, 2, 3],
            info: {}
        };
    },
    created() {
        this.id = this.$route.query.id || ''
    },
    props: {
        EnterpriseSyncInfo: {}
    },
    watch: {
        EnterpriseSyncInfo: {
            deep: true,
            handler(val, oldVal) {
                this.info = val||{}
                this.table.data = val.honor_qualification_list
                this.table.data2 = val.employee_list
                this.table.data3 = val.partner_list
                this.table.data4 = val.company_financing_search_list
            },
            immediate: true
        },
    },
    methods: {
        entType(id) {
            switch (id) {
                case '0':
                    return '大陆企业'
                case '1':
                    return '社会组织'
                case '3':
                    return '中国香港公司'
                case '4':
                    return '事业单位'
                case '5':
                    return '中国台湾公司'
                case '7':
                    return '医院'
                case '8':
                    return '海外公司'
                case '9':
                    return '律师事务所'
                case '-1':
                    return '其他'
                case '10':
                    return '学校'
                case '11':
                    return '机关单位'

                default:
                    break;
            }
        },
        pageChange(pageOption) {
            console.log(pageOption);
            this.pagination.current = pageOption.current
            this.pagination.pageSize = pageOption.pageSize
        },
        pageChange2(pageOption) {
            this.pagination2.current = pageOption.current
            this.pagination2.pageSize = pageOption.pageSize
        },
        pageChange3(pageOption) {
            this.pagination3.current = pageOption.current
            this.pagination3.pageSize = pageOption.pageSize
        },
        pageChange4(pageOption) {
            this.pagination4.current = pageOption.current
            this.pagination4.pageSize = pageOption.pageSize
        },
    }
};
</script>
<style scoped lang="scss">
.tabbox {
    border: 1px solid #d9d9d9;

    .tabtitle {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: #fafafa;
        border-bottom: 1px solid #d9d9d9;

        &:last-child {
            border: none;
        }

        div {
            color: rgba(0, 0, 0, 0.85);
            font-weight: normal;
            font-size: 14px;
            height: 100%;
            line-height: 40px;
            flex: 1;
            margin-left: 20px;
            border-right: 1px solid #d9d9d9;

            &:last-child {
                border: none;
            }
        }
    }

    .tabtext {
        display: flex;
        align-items: center;
        height: 40px;
        width: 100%;
        border-bottom: 1px solid #d9d9d9;

        &:last-child {
            border: none;
        }

        div {
            height: 100%;
            line-height: 40px;
            margin-left: 20px;
            flex: 1;
            border-right: 1px solid #d9d9d9;

            &:last-child {
                border: none;
            }
        }
    }
}
</style>
  