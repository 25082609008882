/* eslint-disable eol-last */
/* eslint-disable indent */
export const CODE_STATE = () => {
    return {
        40000: '请求参数有误',
        40001: '非法请求，验签失败',
        40002: '权限不足',
        40004: '操作过于频繁，请稍后再试',
        40005: '缺少配置，请联系管理员',
        49999: '服务器异常'
    }
}

// export const REQUEST_CODE = (code) => {
//   return code === '200'
// }
export const REQUEST_CODE = '200'
export const SUCCESS_CODE = '200'
