import { isValidPhone, isValidEmail } from '@/utils/inputVerify.js'
import WangEditor from 'wangeditor'
import { IMG_API } from '@/config/index.js'
// 创建富文本编辑器
export function createEditor(that, idSelector) {
  const editor = new WangEditor(idSelector)
  // 配置 onchange 回调函数，将数据同步到 vue 中
  editor.config.onchange = (newHtml) => {
    that.form.enter_profile = newHtml
  }
  editor.config.menus = [
    'fontSize',
    'foreColor',
    'backColor',
    'underline',
    'italic',
    'bold',
    'justify',
    'splitLine',
    'undo',
    'redo',
    'list',
    'table',
    'image'
  ]
  // 设置编辑区域高度为 380px
  editor.config.height = 360
  editor.config.showFullScreen = false
  editor.config.zIndex = 1
  editor.config.uploadImgServer = IMG_API + '/oss/files'
  editor.config.uploadImgShowBase64 = false
  editor.config.showLinkImg = false
  editor.config.uploadImgMaxSize = 5 * 1024 * 1024
  editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png']
  editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图片
  editor.config.uploadFileName = 'file'
  editor.config.uploadImgTimeout = 60000
  editor.config.uploadImgHooks = {
    // 图片上传并返回了结果，但图片插入时出错了
    fail: function (xhr, editor, resData) {
      that.$message.error('上传请求失败')
    },
    // 上传图片出错，一般为 http 请求的错误
    error: function (xhr, editor, resData) {
      that.$message.error('上传请求失败')
    },
    // 上传图片超时
    timeout: function (xhr) {
      that.$message.error('上传请求超时')
    },
    // 图片上传并返回了结果，想要自己把图片插入到编辑器中
    // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
    customInsert: function (insertImgFn, result) {
      // result 即服务端返回的接口
      console.log('customInsert', result)
      // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
      insertImgFn(result.redirect_uri)
    }
  }
  editor.config.customAlert = function (s, t) {
    switch (t) {
      case 'success':
        that.$message.success(s)
        break
      case 'info':
        that.$message.info(s)
        break
      case 'warning':
        that.$message.warning(s)
        break
      case 'error':
        that.$message.error(s)
        break
      default:
        that.$message.info(s)
        break
    }
  }
  editor.config.placeholder = '请输入企业简介'
  // 创建编辑器
  editor.create()
  that.editor = editor
}

export const plainOptions = [
  {
    value: 1,
    label: '是'
  },
  {
    value: 0,
    label: '否'
  }
]

// 必填项控制器,默认下面出现的 字段 fieldName require: false
export const requireControlMap = {
  // 科创企业
  1: ['enterprise_name', 'enter_type1', 'enter_type2', 'selectIds', 'joinProjectDate', 'user_id', 'enter_profile', 'us_code'],
  // 服务团队
  2: ['enterprise_name', 'enter_type1', 'enter_type2', 'selectIds', 'joinProjectDate', 'user_id', 'us_code'],
  // 重点用户
  3: ['enterprise_name', 'enter_type1', 'enter_type2', 'selectIds', 'joinProjectDate', 'user_id'],
  // 园区商户
  4: ['enterprise_name', 'enter_type1', 'enter_type2', 'selectIds', 'joinProjectDate', 'user_id', 'enter_profile'],
  // 当选择【普通用户】类型时，这些字段的 require 设为 true
  5: ['enterprise_name', 'enter_type1', 'enter_type2'],
  // 未开家长
  6: ['enterprise_name', 'enter_type1', 'enter_type2', 'selectIds', 'joinProjectDate', 'user_id'],
  // 用于初始化
  initial: ['selectIds', 'joinProjectDate', 'user_id', 'enter_profile', 'us_code', 'enterprise_logo_url']
}

// 校验规则
export const rules = {
  // 企业类型
  enterprise_type: [{ required: true, message: '请选择企业类型', trigger: 'blur' }],
  enterprise_name: [
    { required: true, message: '请输入企业名称', trigger: 'blur' },
    { max: 50, message: '企业名称最多输入50个字符', trigger: 'change' }
  ],
  enter_type1: [
    { required: true, message: '请选择行业一级分类', trigger: 'blur' }
  ],
  enter_type2: [
    { required: true, message: '请选择行业二级分类', trigger: 'blur' }
  ],
  industry_id: [
    { required: true, message: '请选择行业分类', trigger: ['blur', 'change'] }
  ],
  is_in_incubator: [
    { required: true, message: '请选择是否孵化器内企业', trigger: 'blur' }
  ],
  // 入驻空间
  selectIds: [{ type: 'array', required: false, message: '请选择入驻空间', trigger: ['blur', 'click', 'change'] }],
  // 入驻有效日期
  joinProjectDate: [
    { type: 'array', required: false, message: '请选入驻时间', trigger: 'blur' }
  ],
  // 企服管家
  user_id: [{ required: false, message: '请选择企服管家', trigger: 'blur' }],
  address_id: [{ required: true, message: '请选择入驻项目', trigger: 'blur' }],
  enter_profile: [
    { required: false, message: '请输入企业简介', trigger: 'blur' }
  ],
  us_code: [
    { required: false, message: '请输入统一社会信用代码', trigger: 'blur' },
    { max: 18, message: '统一社会信用代码需要输入18位', trigger: 'change' },
    { min: 18, message: '统一社会信用代码需要输入18位', trigger: 'change' }
  ],
  // 企业logo
  enterprise_logo_url: [
    { required: false, message: '请上传logo' }
  ],
  social_organization: [{ required: true, message: '请输入社会组织名称', trigger: 'blur' }],
  nature_other: [{ required: true, message: '请输入企业性质名称', trigger: 'blur' }],
  // 以下都是非必填
  established_t: [
    { required: false, message: '请选择成立日期', trigger: 'change' }
  ],
  legal_person_info: [
    { required: false, message: '请输入法人姓名', trigger: 'blur' },
    { max: 20, message: '法人姓名最多输入20个字符', trigger: 'change' }
  ],
  legal_person_phone: [
    { max: 11, message: '请输入11位手机号', trigger: 'blur' },
    { min: 11, message: '请输入11位手机号', trigger: 'blur' }
  ],
  enter_link_person: [
    { max: 20, message: '联系人名称最多输入20个字符', trigger: 'change' }
  ],
  phone: [
    { validator: isValidPhone, trigger: 'blur' }
  ],
  annual_tax: [
    { max: 20, message: '年度税收最多输入20位字符', trigger: 'change' }
  ],
  annual_income: [
    { max: 20, message: '年收入最多输入20位字符', trigger: 'change' }
  ],
  // enterprise_telephone: [
  //   { validator: isValidTelOrFax, trigger: 'blur' }
  // ],
  // enterprise_fax: [
  //   { validator: isValidTelOrFax, trigger: 'blur' }
  // ],
  enterprise_mail_box: [
    { validator: isValidEmail, trigger: 'blur' }
  ]
}

// 编辑-入驻信息- table title
export const joinPorjectcolumns = [
  {
    title: '入驻空间',
    dataIndex: 'project_name',
    width: '30%',
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: '入驻开始时间',
    dataIndex: 'show_start_at'
  },
  {
    title: '入驻终止日期',
    dataIndex: 'show_end_at'
  },
  {
    title: '企服管家',
    dataIndex: 'user_name'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
// 编辑-退租信息- table title
export const leasePorjectcolumns = [
  {
    title: '已退租空间',
    dataIndex: 'project_name',
    width: '30%',
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: '入驻开始时间',
    dataIndex: 'show_start_at'
  },
  {
    title: '入驻终止日期',
    dataIndex: 'show_end_at'
  },
  {
    title: '企服管家',
    dataIndex: 'user_name'
  },
  {
    title: '退租操作人',
    dataIndex: 'lease_id_show'
  },
  {
    title: '退租操作时间',
    dataIndex: 'gmt_lease'
  }
]

// mock 数据
// export const enterpriseTypes = [
//   {
//     enterprise_type_id: '1',
//     enterprise_type_name: '科创企业'
//   },
//   {
//     enterprise_type_id: '2',
//     enterprise_type_name: '服务团队'
//   },
//   {
//     enterprise_type_id: '3',
//     enterprise_type_name: '重点用户'
//   },
//   {
//     enterprise_type_id: '4',
//     enterprise_type_name: '园区商户'
//   },
//   {
//     enterprise_type_id: '5',
//     enterprise_type_name: '普通用户'
//   }
// ]

// 只接收 对象 已存在的 key 的值
export const onlyTakeExistingKeys = (existing, params) => {
  if (!existing || !params) return
  Object.keys(existing).forEach(key => {
    existing[key] = params[key] || existing[key]
  })
  // 也可以将处理结果返回
  return existing
}
